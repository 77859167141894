import ButtonGroup from "@/components/common/buttons/ButtonGroup";
import Page from "@/components/layout/Page";
import { useRouter } from "next/router";

function Error({ statusCode }) {
  const is404Error = statusCode === 404;
  const router = useRouter();

  return (
    <Page>
      <div
        className={
          "flex flex-col gap-4 items-center justify-center flex-grow w-full xl:max-w-[1040px] lg:max-w-[960px] max-w-[650px]"
        }
      >
        <div
          className={
            "flex flex-col gap-4 w-full text-primary bg-white bg-opacity-50 rounded-lg p-4"
          }
        >
          <h1 className={"font-gBold text-3xl"}>{is404Error ? "404: PAGE NOT FOUND" : "UH-OH!"}</h1>
          <div>
            {is404Error ? (
              <p>{"The page you requested couldn't be found."}</p>
            ) : (
              <>
                <p>{"Looks like something went wrong on our end."}</p>
                <p className={"font-gBold text-lg"}>{"Meanwhile you can try the following:"}</p>
                <ul className={"list-disc list-inside"}>
                  <li>Try again after some time</li>
                  <li>Go to the homepage and start over again</li>
                  <li>
                    Get in touch with our support team by clicking on the <b>?</b> button on the top
                    right of the page
                  </li>
                </ul>
              </>
            )}
          </div>
        </div>
        <ButtonGroup primaryLabel={"Go To The Homepage"} primaryAction={() => router.push("/")} />
      </div>
    </Page>
  );
}

Error.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default Error;
